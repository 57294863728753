import React from 'react'
import News from '../components/News'
import SEO from '../components/seo'
import { INewsItem } from '../types'

const getBlog = (): INewsItem[] => []

const BlogPage: React.FC = () => {
  const title = 'Blog'
  return (
    <>
      <SEO title={title} />
      <News title={title} items={getBlog()} />
    </>
  )
}

export default BlogPage
